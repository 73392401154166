import {
    DATE_FORMAT,
    IChangeReservationStatus,
    ICreateReservation,
    ICreateWalkinReservation,
    IImportResult,
    ILocalizedError,
    IPublicReservationForReview,
    IReservation,
    IReservations,
    IUpdateReservation,
    IUpdateWalkinReservation,
    ReservationStatus,
    Rest,
    SERVER_DATE_FORMAT,
} from '@localina/core';
import {
    useMutation,
    UseMutationOptions,
    useQueries,
    useQuery,
    useQueryClient,
    UseQueryOptions,
} from '@tanstack/react-query';
import { compact, isEqual } from 'lodash';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useRef, useState } from 'react';
import { LocalinaApiContext } from '../../../index';
import { downloadExportFile, ServiceApiConstants } from '../../utils';
import { IReservationsFilters, useFilterReservations } from '../../utils/ReservationsUtils';
import { useRestaurantId } from '../../utils/RestaurantUtils';
import { useRemoveReservationFromUnreadQueryCache } from './notifications';
import { queryKeys } from './query-keys';
import { usePreservedRestaurant, useRestaurant } from './restaurants';

function useReservations(date: string, options?: UseQueryOptions<IReservations, ILocalizedError>) {
    const restaurantId = useRestaurantId();

    return useQuery({
        queryFn: ({ signal }) => {
            return LocalinaApiContext.serviceApi.getReservations(restaurantId, date, signal);
        },
        queryKey: queryKeys.restaurants.single.reservations.onDate(restaurantId, date),
        ...options,
    });
}

function useReservationsWithPrefetchingAdjacentDates(
    date: string,
    options?: UseQueryOptions<IReservations, ILocalizedError>,
) {
    const queryClient = useQueryClient();
    const restaurantId = useRestaurantId();
    const refToLastDatesFetched = useRef<string[]>([]);

    const reservationsQuery = useReservations(date, options);

    useEffect(() => {
        if (date && reservationsQuery.isSuccess) {
            const dateTime = DateTime.fromFormat(date, SERVER_DATE_FORMAT);
            const dates = [
                dateTime.plus({ day: 1 }).toFormat(SERVER_DATE_FORMAT),
                dateTime.minus({ day: 1 }).toFormat(SERVER_DATE_FORMAT),
            ];

            refToLastDatesFetched.current.forEach((dateLastFetched) => {
                if (!dates.includes(dateLastFetched) && dateLastFetched !== date) {
                    void queryClient.cancelQueries({
                        queryKey: queryKeys.restaurants.single.reservations.onDate(restaurantId, dateLastFetched),
                    });
                }
            });

            dates.forEach((dateToPrefetch) => {
                void queryClient.prefetchQuery({
                    queryKey: queryKeys.restaurants.single.reservations.onDate(restaurantId, dateToPrefetch),
                    queryFn: ({ signal }) => {
                        return LocalinaApiContext.serviceApi.getReservations(restaurantId, dateToPrefetch, signal);
                    },
                    staleTime: 5 * 1000,
                });
            });

            refToLastDatesFetched.current = dates;
        }
    }, [date, reservationsQuery.isSuccess]);

    return reservationsQuery;
}

function useReservation(reservationId: string, options?: UseQueryOptions<IReservation, ILocalizedError>) {
    const restaurantId = useRestaurantId();

    return useQuery({
        queryFn: () => {
            return LocalinaApiContext.serviceApi.getReservation(restaurantId, reservationId);
        },
        queryKey: queryKeys.restaurants.single.reservations.single(restaurantId, reservationId),
        ...options,
    });
}

function usePublicReservationByToken(
    restaurantId: string,
    token: string,
    options?: UseQueryOptions<IPublicReservationForReview, ILocalizedError>,
) {
    return useQuery({
        queryFn: () => {
            return Rest.getRequest<IPublicReservationForReview>(
                ServiceApiConstants.getPublicReservationByToken(restaurantId, token).toString(),
            );
        },
        queryKey: queryKeys.pendingReservation(token),
        ...options,
    });
}

const useUpdateReservationStatusByToken = (
    options?: UseMutationOptions<
        void,
        ILocalizedError,
        {
            restaurantId: string;
            reservationId: string;
            token: string;
            status: ReservationStatus;
        }
    >,
) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({ restaurantId, token, reservationId, status }) => {
            const changeStatusRequest: IChangeReservationStatus = {
                updatedStatus: status,
            };
            return LocalinaApiContext.serviceApi.updateReservationStatusByToken(
                restaurantId,
                reservationId,
                token,
                changeStatusRequest,
            );
        },
        onSuccess: (_data, variables) => {
            queryClient.setQueryData(queryKeys.pendingReservation(variables.token), (data) => {
                return data ? { ...data, status: variables.status } : undefined;
            });
        },
        ...options,
    });
};

const useCreateReservation = (
    options?: UseMutationOptions<
        void,
        ILocalizedError,
        {
            reservation: IReservation;
            forceCreate?: boolean;
            sendConfirmationMail?: boolean;
            sendSmsReminder?: boolean;
            sendEmailReminder?: boolean;
        }
    >,
) => {
    const restaurant = useRestaurant();
    const queryClient = useQueryClient();

    return useMutation(
        ({ reservation, forceCreate = false, sendConfirmationMail = false }) => {
            const staffTag = reservation.staffTagId
                ? restaurant.data?.configuration.staffTags.find(({ id }) => id === reservation.staffTagId)?.staffTag
                : undefined;

            const guestInfo = reservation.guestInfo!;

            const request: ICreateReservation = {
                participants: reservation.participants,
                reservationDateTime: reservation.reservationDateTime,
                dates: reservation.dates?.map((date) =>
                    DateTime.fromFormat(date, DATE_FORMAT).toFormat(SERVER_DATE_FORMAT),
                ),
                guestInfo: {
                    firstName: guestInfo.firstName || '',
                    company: guestInfo.company || '',
                    salutation: guestInfo.salutation,
                    allergies: guestInfo.allergies,
                    restaurantComment: guestInfo.restaurantComment,
                    lastName: guestInfo.lastName,
                    email: guestInfo.email || '',
                    subscribeToNewsletter: Boolean(guestInfo.subscribeToNewsletter),
                    phoneNumber: reservation.phoneNumber || guestInfo.phoneNumbers[0],
                },
                comment: reservation.comment || '',
                occupancyTime: reservation.occupancyTime,
                shiftId: reservation.shiftId,
                areaIds: reservation.areaIds,
                tableIds: reservation.tableIds ?? [],
                documents: reservation.documents ?? [],
                color: reservation.color,
                sendConfirmationMail: Boolean(sendConfirmationMail),
                forceCreate,
                staffTag,
                sendSmsReminder: Boolean(reservation.sendSmsReminder),
                sendEmailReminder: Boolean(reservation.sendEmailReminder),
            };
            return LocalinaApiContext.serviceApi.createReservation(restaurant.data?.id || '', request);
        },
        {
            onSuccess: (_data, variables) => {
                return Promise.all(
                    (variables.reservation.dates || []).map((date) =>
                        queryClient.invalidateQueries(
                            queryKeys.restaurants.single.reservations.onDate(
                                restaurant.data?.id || '',
                                DateTime.fromFormat(date, DATE_FORMAT).toFormat(SERVER_DATE_FORMAT),
                            ),
                        ),
                    ),
                );
            },
            ...options,
        },
    );
};

const useCreateWalkinReservation = (
    options?: UseMutationOptions<
        void,
        ILocalizedError,
        {
            reservation: IReservation;
        }
    >,
) => {
    const restaurant = useRestaurant();
    const queryClient = useQueryClient();

    return useMutation(
        ({ reservation }) => {
            const request: ICreateWalkinReservation = {
                participants: reservation.participants,
                walkinDateTime: reservation.reservationDateTime,
                occupancyTime: reservation.occupancyTime,
                shiftId: reservation.shiftId,
                areaIds: reservation.areaIds,
                color: reservation.color,
                tableIds: reservation.tableIds ?? [],
            };
            return LocalinaApiContext.serviceApi.createWalkinReservation(restaurant.data?.id || '', request);
        },
        {
            onSuccess: (_data, variables) => {
                const walkinDate = DateTime.fromISO(variables.reservation.reservationDateTime).toFormat(
                    SERVER_DATE_FORMAT,
                );
                return queryClient.invalidateQueries(
                    queryKeys.restaurants.single.reservations.onDate(restaurant.data?.id || '', walkinDate),
                );
            },
            ...options,
        },
    );
};
const useUpdateWalkinReservation = (
    options?: UseMutationOptions<
        void,
        ILocalizedError,
        {
            reservation: IReservation;
        }
    >,
) => {
    const restaurant = useRestaurant();
    const queryClient = useQueryClient();

    return useMutation(
        ({ reservation }) => {
            const request: IUpdateWalkinReservation = {
                occupancyTime: reservation.occupancyTime,
                shiftId: reservation.shiftId,
                areaIds: reservation.areaIds,
                tableIds: reservation.tableIds ?? [],
                color: reservation.color,
                participants: Number(reservation.participants),
            };
            return LocalinaApiContext.serviceApi.updateWalkinReservation(
                restaurant.data?.id || '',
                reservation.id,
                request,
            );
        },
        {
            onSuccess: (_data, variables) => {
                const walkinDate = DateTime.fromISO(variables.reservation.reservationDateTime).toFormat(
                    SERVER_DATE_FORMAT,
                );
                return queryClient.invalidateQueries(
                    queryKeys.restaurants.single.reservations.onDate(restaurant.data?.id || '', walkinDate),
                );
            },
            ...options,
        },
    );
};
const useUpdateReservation = (
    options?: UseMutationOptions<
        void,
        ILocalizedError,
        {
            reservation: IReservation;
            oldReservation: IReservation;
            sendConfirmationMail?: boolean;
        }
    >,
) => {
    const restaurant = useRestaurant();
    const queryClient = useQueryClient();
    const updateReservationStatusMutation = useUpdateReservationStatus();

    return useMutation(
        ({ reservation, sendConfirmationMail = false }) => {
            const staffTag = reservation.staffTagId
                ? restaurant.data?.configuration.staffTags.find(({ id }) => id === reservation.staffTagId)?.staffTag
                : undefined;

            const guestInfo = reservation.guestInfo!;

            const request: IUpdateReservation = {
                participants: reservation.participants,
                reservationDateTime: reservation.reservationDateTime,
                firstName: guestInfo.firstName || '',
                lastName: guestInfo.lastName,
                phoneNumber: reservation.phoneNumber || guestInfo.phoneNumbers[0],
                comment: reservation.comment || '',
                company: guestInfo.company || '',
                salutation: reservation.guestInfo?.salutation,
                guestStatus: reservation.guestInfo?.guestStatus || null,
                allergies: guestInfo.allergies || '',
                restaurantComment: guestInfo.restaurantComment || '',
                occupancyTime: reservation.occupancyTime,
                shiftId: reservation.shiftId,
                areaIds: reservation.areaIds,
                tableIds: reservation.tableIds ?? [],
                documents: reservation.documents ?? [],
                sendConfirmationMail: Boolean(sendConfirmationMail),
                color: reservation.color,
                staffTag,
                sendSmsReminder: Boolean(reservation.sendSmsReminder),
                sendEmailReminder: Boolean(reservation.sendEmailReminder),
            };

            return LocalinaApiContext.serviceApi.updateReservation(restaurant.data?.id || '', reservation.id, request);
        },
        {
            onSuccess: async (_data, { reservation, oldReservation }) => {
                const oldDate = DateTime.fromISO(oldReservation.reservationDateTime).toFormat(SERVER_DATE_FORMAT);
                const newDate = DateTime.fromISO(reservation.reservationDateTime).toFormat(SERVER_DATE_FORMAT);
                const dates = oldDate !== newDate ? [oldDate, newDate] : [oldDate];

                if (
                    reservation.status !== oldReservation?.status &&
                    reservation.status !== ReservationStatus.GUEST_CANCELLED
                ) {
                    await updateReservationStatusMutation.mutateAsync({
                        reservationId: reservation.id,
                        status: reservation.status,
                    });
                }

                return Promise.all(
                    dates.map((date) =>
                        queryClient.invalidateQueries(
                            queryKeys.restaurants.single.reservations.onDate(restaurant.data?.id || '', date),
                        ),
                    ),
                );
            },
            ...options,
        },
    );
};

const useUpdateReservationStatus = (
    options?: UseMutationOptions<
        void,
        ILocalizedError,
        {
            reservationId: string;
            status: ReservationStatus;
            reservationDate?: string;
        }
    >,
) => {
    const restaurantQuery = usePreservedRestaurant(false);
    const restaurantId = useRestaurantId(restaurantQuery.data?.id);

    const queryClient = useQueryClient();

    const removeReservationFromUnreadQueryCache = useRemoveReservationFromUnreadQueryCache();
    const updateReservationStatusInQueryCache = useUpdateReservationStatusInQueryCache();

    return useMutation({
        mutationFn: ({ reservationId, status }) => {
            const changeStatusRequest: IChangeReservationStatus = {
                updatedStatus: status,
            };
            return LocalinaApiContext.serviceApi.updateReservationStatus(
                restaurantId,
                reservationId,
                changeStatusRequest,
            );
        },
        ...options,
        onSuccess: (data, variables, context) => {
            if (options?.onSuccess) {
                options.onSuccess(data, variables, context);
            }
            const { reservationDate, reservationId, status } = variables;
            removeReservationFromUnreadQueryCache(reservationId);

            if (reservationDate) {
                updateReservationStatusInQueryCache(restaurantId, reservationDate, reservationId, status);
            }
        },
        onError: (_error, variables) => {
            const queryKeysToRefetch = compact([
                queryKeys.restaurants.single.reservations.single(restaurantId, variables.reservationId),
                queryKeys.restaurants.single.notifications.unreadReservations(restaurantId),
                variables.reservationDate &&
                    queryKeys.restaurants.single.reservations.onDate(restaurantId, variables.reservationDate),
            ]);
            return queryClient.refetchQueries({
                type: 'active',
                predicate: (query) => queryKeysToRefetch.some((queryKey) => isEqual(queryKey, query.queryKey)),
            });
        },
    });
};

const useUpdateReservationStatusInQueryCache = () => {
    const queryClient = useQueryClient();

    return useCallback(
        (restaurantId: string, reservationDate: string, reservationId: string, status: ReservationStatus) => {
            queryClient.setQueryData<ReturnType<typeof useReservations>['data']>(
                queryKeys.restaurants.single.reservations.onDate(restaurantId, reservationDate),
                (prevData) =>
                    prevData
                        ? {
                              ...prevData,
                              reservations: prevData.reservations.map((res) =>
                                  res.id === reservationId
                                      ? {
                                            ...res,
                                            status,
                                        }
                                      : res,
                              ),
                          }
                        : undefined,
            );
        },
        [],
    );
};
const useUpdateReservationTables = (
    options?: UseMutationOptions<
        void,
        ILocalizedError,
        {
            reservationId: string;
            tableIds: string[] | null;
            mode: 'merge' | 'change';
        }
    >,
) => {
    const restaurantId = useRestaurantId();

    return useMutation({
        mutationFn: ({ reservationId, tableIds, mode }) => {
            return LocalinaApiContext.serviceApi.updateReservationTableIds(restaurantId, reservationId, tableIds, mode);
        },
        ...options,
    });
};
const useUpdateWalkinTables = (
    options?: UseMutationOptions<
        void,
        ILocalizedError,
        {
            reservationId: string;
            tableIds: string[] | null;
            mode: 'merge' | 'change';
        }
    >,
) => {
    const restaurantId = useRestaurantId();

    return useMutation({
        mutationFn: ({ reservationId, tableIds, mode }) => {
            return LocalinaApiContext.serviceApi.updateWalkinTableIds(restaurantId, reservationId, tableIds, mode);
        },
        ...options,
    });
};

const useReservationsImportPreview = (options?: UseMutationOptions<IImportResult, ILocalizedError, File>) => {
    const restaurantId = useRestaurantId();

    return useMutation({
        mutationFn: (file) => {
            return LocalinaApiContext.serviceApi.getReservationsImportPreview(restaurantId, file);
        },
        ...options,
    });
};

const useReservationsImport = (options?: UseMutationOptions<IImportResult, ILocalizedError, File>) => {
    const restaurantId = useRestaurantId();

    return useMutation({
        mutationFn: (file) => {
            return LocalinaApiContext.serviceApi.saveReservationsImport(restaurantId, file);
        },
        ...options,
    });
};
const useValidatedReservationsExport = (options?: UseMutationOptions<File, ILocalizedError, File>) => {
    const restaurantId = useRestaurantId();

    return useMutation({
        mutationFn: (file) => {
            return LocalinaApiContext.serviceApi.getValidatedReservationsExport(restaurantId, file);
        },
        ...options,
    });
};

const useReservationsExport = (
    options?: UseMutationOptions<
        File,
        ILocalizedError,
        {
            reservations: string[];
            fileName: string;
        }
    >,
) => {
    const restaurantId = useRestaurantId();

    return useMutation({
        mutationFn: (variables) => {
            return LocalinaApiContext.serviceApi.getExportReservations(restaurantId, variables.reservations);
        },
        onSuccess: (data, variables) => {
            const lastDotIndex = variables.fileName.lastIndexOf('.');
            const type = variables.fileName.slice(lastDotIndex + 1);
            const name = variables.fileName.slice(0, lastDotIndex);
            downloadExportFile(data, { name, type });
        },
        ...options,
    });
};

const useReservationsFromDates = (dates: string[], options?: UseQueryOptions<IReservations, ILocalizedError>) => {
    const restaurantId = useRestaurantId();
    return useQueries({
        queries: dates.map((date) => ({
            queryFn: () => {
                return LocalinaApiContext.serviceApi.getReservations(restaurantId, date);
            },
            queryKey: queryKeys.restaurants.single.reservations.onDate(restaurantId, date),
            ...options,
        })),
    });
};

const useReservationsExportForMultipleDates = (
    options?: UseMutationOptions<
        any,
        ILocalizedError,
        {
            dates: string[];
            fileName: string;
            filters: IReservationsFilters;
        }
    >,
) => {
    const [queriesControl, setQueriesControl] = useState<{
        dates: string[];
        fetchingEnabled: boolean;
        filters?: IReservationsFilters;
    }>({
        fetchingEnabled: false,
        filters: undefined,
        dates: [],
    });

    const reservationsExport = useReservationsExport();
    const reservationsFromDates = useReservationsFromDates(queriesControl.dates, {
        enabled: queriesControl.fetchingEnabled,
        cacheTime: 0,
    });
    const allSettled = reservationsFromDates.every((query) => query.isSuccess || query.isError);

    const reservations = useRef<null | IReservation[]>(null);

    const filterReservations = useFilterReservations(queriesControl.filters);

    const resetAll = () => {
        setQueriesControl((prevState) => ({ ...prevState, dates: [], fetchingEnabled: false }));
        reservations.current = null;
        somethingWentWrong.current = false;
    };

    const somethingWentWrong = useRef<boolean>(false);

    useEffect(() => {
        if (queriesControl.fetchingEnabled && allSettled) {
            if (reservationsFromDates.every((query) => query.isSuccess)) {
                reservations.current = filterReservations(
                    reservationsFromDates.flatMap((page) => page.data?.reservations ?? []),
                );
            } else {
                somethingWentWrong.current = true;
            }
        }
    }, [allSettled, queriesControl.fetchingEnabled]);

    return useMutation((variables) => {
        setQueriesControl({ dates: variables.dates, fetchingEnabled: true, filters: variables.filters });

        // Return a promise that resolves when the data is fetched
        return new Promise((resolve, reject) => {
            const checkDataFetched = () => {
                if (reservations.current !== null) {
                    // Resolve the mutation
                    resolve(
                        // Call the reservations export mutation
                        reservationsExport.mutate({
                            reservations: reservations.current.map((res) => res.id),
                            fileName: variables.fileName,
                        }),
                    );
                    resetAll();
                } else if (somethingWentWrong.current) {
                    // something went wrong
                    reject();
                    resetAll();
                } else {
                    // Check again after a short delay
                    setTimeout(checkDataFetched, 100);
                }
            };

            // Start checking if data is fetched
            checkDataFetched();
        });
    }, options);
};

export {
    useCreateReservation,
    useCreateWalkinReservation,
    usePublicReservationByToken,
    useReservation,
    useReservations,
    useReservationsExport,
    useReservationsExportForMultipleDates,
    useReservationsImport,
    useReservationsImportPreview,
    useReservationsWithPrefetchingAdjacentDates,
    useUpdateReservation,
    useUpdateReservationStatus,
    useUpdateReservationStatusByToken,
    useUpdateReservationTables,
    useUpdateWalkinReservation,
    useUpdateWalkinTables,
    useValidatedReservationsExport,
};
